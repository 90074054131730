import styled, { keyframes } from 'styled-components';
import LogoImage from '../../../assets/images/nav.svg';
import { theme, sizes } from '../../../theme';
import { media } from '../../../utilities/grid';
import Modal from "../partial/Modal";

export const FooterWrapper = styled.section`
  background: ${theme.lightGrey};
`;

export const FooterContent = styled.section`
  max-width: ${sizes.maxWidth};
  margin: 0 auto;
  color: ${theme.bootsBlue};
  padding: 1rem 1rem 8rem;
`;

export const FooterImageWrapper = styled.div`
  padding: 1rem 0;
  width: 100%;
  display: flex;
`;

export const FooterLogo = styled.div`
  width: 6rem;
  height: 3rem;
  ${`background-image: url(${LogoImage})`};
  background-repeat: no-repeat;
  background-size: contain;
`;

export const DetailsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 0;
`;

export const FooterLinks = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 1rem;
  ${media.from.desktop`
    width: 40%;
  `}
`;

export const FooterLinkWrapper = styled.p`
  color: ${theme.textGrey};
  font-size: 18px;
  padding: 0.5rem 0;
`;

export const FooterLink = styled.a`
  color: ${theme.bootsBlue};
  font-weight: bold;
`;

export const FooterDisclaimer = styled.div`
  ${media.from.desktop`
    width: 55%;
  `}
`;

export const FooterDisclaimerItem = styled.p`
  font-size: 14px;
  color: ${theme.textGrey};
  margin-bottom: 1rem;
`;

export const MainHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const LogoWrapper = styled.div`
  padding: 1.25rem;
`;

export const LogoSrc = styled.div`
  width: 5rem;
  height: 2.7rem;
  ${`background-image: url(${LogoImage})`};
  background-repeat: no-repeat;
  background-size: contain;
`;

export const MainBanner = styled.div`
  background-color: #cc0033;
  color: white;
  padding: 1.25rem;
  text-align: center;
`;

export const LoaderWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  background-color: ${theme.lightGreenBackground};
  span {
    border-width: 5px !important;
  }
`;


// MODAL DIALOG

export const overlayAppear = keyframes`
  from {
    opacity: 0;
    backdrop-filter: blur(0px);
  }
  to {
    opacity: 1;
    backdrop-filter: blur(3px);
  }
`;

export const modalGrowAppear = keyframes`
  from {
    opacity: 0;
    transform: scale(50%) translate(-50%, -50%);
  }
  to {
    opacity: 1;
    transform: scale(100%) translate(-50%, -50%);
  }
`;

export const slideInFromBottom = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50%) translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
`;

export const slideInFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50%) translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
`;

export const ModalOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(3px);
  animation: ${overlayAppear} 0.5s ease-in-out;
`;

export const StyledModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;

  .modal-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform-origin: 0% 0%;
    box-sizing: border-box;
    max-width: 50%;
    width: 100%;
    padding: 1.5em 1em;
    border: 0 none;
    background-color: white;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
    text-align: center;
    opacity: 1;
    transform: scale(100%) translate(-50%, -50%);
    animation: ${modalGrowAppear} 0.5s ease-in-out;
    z-index: 10001;
    
    h2 {
      font-size: 22px;
      font-weight: bold;
      margin: 0.5em 0 1em;
    }
    
    p {
      font-size: 16px;
      margin: 0 1em 1em;
      text-align: left;
    }
    
    footer .button-container {
      padding: 0;
    }
  }
  
  &.closing {
    ${ModalOverlay} {
      animation: none;
      transition: opacity 0.5s ease-in, backdrop-filter 0.5s ease-in;
      opacity: 0;
      backdrop-filter: blur(0px);
    }
    .modal-content {
      animation: none;
      transition: transform 0.5s ease-in, opacity 0.5s ease-in;
      opacity: 0;
      transform: scale(50%) translate(-50%, -50%);
    }
  }

  &.dropdown .modal-content {
    margin: 0 auto auto;
    height: 90%;
    max-height: 90%;
    opacity: 1;
    top: 0;
    transform: translate(-50%, 0);;
    transform-origin: top center;
    animation: ${slideInFromTop} 0.5s ease-in-out;
    
    h2 {
      margin-top: 1.5em;
    }
    
    footer.sticky {
      padding: 1em 0;
    }
  }

  &.dropdown.closing .modal-content {
    animation: none;
    transition: transform 0.5s ease-in;
    transform: translateY(-100%) translateX(-50%);
  }

  @media screen and (max-width: 767px) {
    
    .modal-content,
    &.reachable .modal-content {
      max-width: 90%;
    }
    
    &.reachable .modal-content {
      bottom: 0;
      left: 50%;
      height: 90%;
      max-height: 670px;
      padding: 0;
      margin: auto auto 0;
      transform: translateY(0) translateX(-50%);
      opacity: 1;
      transform-origin: bottom center;
      animation: ${slideInFromBottom} 0.5s ease-in-out;
      
      footer .button-container {
        padding: 1.5em 0;
      }
    }

    &.reachable.closing .modal-content {
      animation: none;
      transition: transform 0.5s ease-in;
      transform: translateY(100%) translateX(-50%);
    }
  }
`;



export const InfoDialog = styled(Modal)`
  .modal-content {
      max-width: 360px;
  }
`;


export const ClaimsDialog = styled(Modal)`
  .modal-content {
      max-width: 360px;
  }
`;