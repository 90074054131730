import styled from 'styled-components';
import { theme } from './theme';
import { Link } from 'react-router-dom';

export const MainApp = styled.div`
  width: 100%;
  margin: 0 auto;
`;

export const LinkButton = styled(Link)`
  display: flex;
  width: 12.2rem;
  height: 2rem;
  background: ${theme.bootsBlue};
  padding: 0.3rem;
  color: ${theme.white};
  border-radius: 0.25rem;
  justify-content: center;
  align-items: center;
`;

export const Button = styled.button`
  display: flex;
  background: ${theme.bootsBlue};
  height: 40px;
  padding: 0 3.3rem;
  color: ${theme.white};
  border: none;
  border-radius: 0.25rem;
  justify-content: center;
  align-items: center;
  font-size: inherit;
  font-family: inherit;
  cursor: pointer;
  pointer-events: auto;
`;

export const StickyLinkWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  overflow: hidden;
  bottom: 0;
  padding: 1rem 0;
  background-color: ${theme.lightGrey};
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.05);
  ${Button} {
    border: 1px solid ${theme.white};
  }
`;
