import { client } from '../client/apollo';
import { SEND_ANALYTICS_EVENT } from '../services/dataServices.js';

const sendDataToBQ = async (name, data) => {
  const now = Math.round(Date.now() / 1000)
  try {
    await client.mutate({
        mutation: SEND_ANALYTICS_EVENT,
        variables: { name, timestamp: now, metadata: flattenObject(data) }
    });
  } catch (e) {
    if (e.graphQLErrors.some(error => error.type === 'Unauthorized')) {
      console.log('User not authorised');
    } else {
      console.warn('Failed to send data to BigQuery', e);
    }
  }
}

export const sendBQPageView = (title, location) => {

  sendDataToBQ('pageview', { title, location });
} ;

export const trackBQEvent = (eventName, data = {}, products = []) => {

  if (products.length > 0) {
    data = {
      ...data,
      ...mapProductsToBQEventData(products)
    };
  }

  sendDataToBQ(eventName, data);
};

const mapProductsToBQEventData = (products) => {

  let totalPrice = 0;
  const mappedItems = [];
  products.forEach(product => {
    const productPrice = (product.offerPrice ? product.offerPrice : product.rrpPrice) / 100; // rrpPrice is in pennies, so we need to divide it by 100
    const discount = product.offerPrice ? product.rrpPrice - product.offerPrice : 0;
    totalPrice += productPrice;
    mappedItems.push({
      item_id: product.id,
      item_brand: product.brand,
      item_name: product.name,
      item_category: 'Supplements',
      item_category2: product.type === 'multi' ? 'Multivitamins' : 'Vitamins',
      discount: discount,
      price: productPrice,
      quantity: 1
    });
  });
  return {
    value: totalPrice,
    items: mappedItems
  }
}

function flattenObject(obj, parentKey = '', result = []) {
    // Iterate through each property of the object
    Object.keys(obj).forEach(key => {
        let fullKey = parentKey ? `${parentKey}_${key}` : key;

        // Check if the property value is an object, and not null, and not an array
        if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
            // Recursively flatten the object
            flattenObject(obj[key], fullKey, result);
        } else if (Array.isArray(obj[key])) {
            // Process each element in the array
            obj[key].forEach((item, index) => {
                if (typeof item === 'object' && item !== null) {
                    // Recursively flatten the object in the array
                    flattenObject(item, `${fullKey}_${index}`, result);
                } else {
                    // Append the array index to the key and add to the result, converting the value to string
                    result.push({ key: `${fullKey}_${index}`, value: String(item) });
                }
            });
        } else {
            // It's a simple property, add it directly to the result, converting the value to string
            result.push({ key: fullKey, value: String(obj[key]) });
        }
    });

    return result;
}