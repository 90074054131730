import React, { Suspense, lazy } from 'react';
import { client } from './client/apollo';
import { Route, Switch, Redirect } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
// components
import Loader from './components/views/partial/Loader';
import ScrollToTop from './utilities/ScrollToTop';
// styles
import GlobalStyle from './assets/fonts/fonts';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme';
import './main.css';
import { MainApp } from './AppStyles';
// context
import { ApiDataContextProvider } from './context/ApiDataContext';
import { GlobalContextProvider } from './context/GlobalContext';
import { PageViewTracking } from './services/analytics';

const Landing = lazy(() => import('./components/views/Landing'));
const Result = lazy(() => import('./containers/ResultsContainer'));

function Routes() {
  return (
    <Suspense fallback={<Loader />}>
      <PageViewTracking />
      <Switch>
        <Route path="/result">
          <ScrollToTop />
          <ApiDataContextProvider>
            <Result />
          </ApiDataContextProvider>
        </Route>
        <Route exact path="/">
          <Landing />
        </Route>
        <Redirect to="/" />
      </Switch>
    </Suspense>
  );
}

function App() {
  return (
    <ThemeProvider theme={{ ...theme, fontFamily: 'BootsSharp' }}>
      <MainApp>
        <GlobalContextProvider>
          <ApolloProvider client={client}>
            <BrowserRouter>
              <GlobalStyle />
              <Routes />
            </BrowserRouter>
          </ApolloProvider>
        </GlobalContextProvider>
      </MainApp>
    </ThemeProvider>
  );
}

export default App;
