import React, { useState } from 'react';

export const GlobalContext = React.createContext(null);

export const GlobalContextProvider = props => {
  const [showConsultation, setShowConsultation] = useState(false);

  const context = {
    showConsultation,
    setShowConsultation,
  };

  return <GlobalContext.Provider value={context}>{props.children}</GlobalContext.Provider>;
};
