import gql from 'graphql-tag';

const productFragment = gql`
  fragment ProductParts on Product {
    type
    id
    productId
    catEntryId
    score
    brand
    name
    quantity
    thumbnailUrl
    backgroundColor
    foregroundColor
    rrpPrice
    offerPrice
    offerText
    description
    supportedHealthAreas
    healthClaimsDescription
    nutrients {
      name
      value
    }
  }
`;

const productCategoryFragment = gql`
  fragment ProductCategoryParts on ProductCategory {
    id
    type
    name
    score
    description
    products {
      ...ProductParts
    }
  }
  ${productFragment}
`;

const goalFragment = gql`
  fragment GoalParts on Goal {
    id
    name
    color
    iconUrl
    score
    selected
  }
`;

const healthProfileFragment = gql`
  fragment HealthProfileParts on HealthProfile {
    recommendedProductCategories {
      ...ProductCategoryParts
    }
    goals {
      ...GoalParts
    }
    warnings {
      id
      name
    }
  }
  ${goalFragment}
  ${productCategoryFragment}
`;

export const GET_CONFIG = gql`
  query Config {
    config {
      bundleInitialMaxItemCount
      catalogId
      storeId
      nutrientUpperLimits {
        id
        name
        value
      }
    }
  }
`;

export const GUEST_CREATE = gql`
  mutation GuestCreate {
    guest_create
  }
`;

export const HEALTH_PROFILE = gql`
  query HealthProfile {
    healthProfile {
      ...HealthProfileParts
    }
  }
  ${healthProfileFragment}
`;

export const SELECT_GOALS = gql`
  mutation UserSelectGoals($ids: [String]!) {
    user_selectGoals(ids: $ids) {
      ...HealthProfileParts
    }
  }
  ${healthProfileFragment}
`;

export const SEND_ANALYTICS_EVENT = gql`
  mutation AnalyticsSendEvent($name: String!, $timestamp: Float!, $metadata: [KeyValueInput]) {
    analytics_sendEvent(name: $name, timestamp: $timestamp, metadata: $metadata)
  }
`;

export const getLocalData = itemName => {
  return JSON.parse(localStorage.getItem(itemName));
};

export const setLocalData = (itemName, data) => {
  localStorage.setItem(itemName, JSON.stringify(data));
};
