import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { theme } from '../../../theme';
import { LoaderWrapper } from './partialStyles';

const Loader = () => {
  return (
    <LoaderWrapper>
      <ClipLoader color={theme.bootsBlue} size={45} />
    </LoaderWrapper>
  );
};

export default Loader;
