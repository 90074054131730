import { createGlobalStyle } from 'styled-components';

import BootsSharpBoldEot from './BootsSharp-Bold.eot';
import BootsSharpBoldWoff from './BootsSharp-Bold.woff';
import BootsSharpBoldWoff2 from './BootsSharp-Bold.woff2';
import BootsSharpBoldTtf from './BootsSharp-Bold1.ttf';
import BootsSharpRegularEot from './BootsSharp-Regular.eot';
import BootsSharpRegularWoff from './BootsSharp-Regular.woff';
import BootsSharpRegularWoff2 from './BootsSharp-Regular.woff2';
import BootsSharpRegularTtf from './BootsSharp-Regular1.ttf';

const GlobalStyle = createGlobalStyle`
    body {
      font-family: ${props => props.theme.fontFamily};
    }

    @font-face {
        font-family: 'BootsSharp';
        src: local('BootsSharp'), local('BootsSharp'),
        url(${BootsSharpBoldEot}) format('eot'),
        url(${BootsSharpBoldWoff}) format('woff'),
        url(${BootsSharpBoldWoff2}) format('woff2'),
        url(${BootsSharpBoldTtf}) format('ttf');
        font-weight: 800;
        font-style: normal;
    }
    @font-face {
        font-family: 'BootsSharp';
        src: local('BootsSharp'), local('BootsSharp'),
        url(${BootsSharpRegularEot}) format('eot'),
        url(${BootsSharpRegularWoff}) format('woff'),
        url(${BootsSharpRegularWoff2}) format('woff2'),
        url(${BootsSharpRegularTtf}) format('ttf');
        font-weight: 400;
        font-style: normal;
    }
`;

export default GlobalStyle;
