import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { sendGA4PageView, trackGA4Event, initGA4 } from "./ga";
import { sendBQPageView, trackBQEvent } from "./bq";

export const initAnalytics = () => {
    initGA4();
}

export const PageViewTracking = () => {
  
  const { pathname } = useLocation();

  const sendPageView = (title, location) => {

    // set page title
    document.title = title;

    sendGA4PageView(title, location);
    sendBQPageView(title, location);
  } ;

  useEffect(() => {

    if (window.dataLayer) {

      switch (pathname) {

        case "":
        case "/":
          sendPageView("Vitamin Quiz | What vitamins should I be taking? | Boots", pathname);
          break;
        case "/result/goals":
          sendPageView("Goals | Boots Vitamins Quiz", pathname);
          break;
        case "/result/bundle-intro":
          sendPageView("Bundle Intro | Boots Vitamins Quiz", pathname);
          break;
        case "/result/recommended-categories":
          sendPageView("Recommended Categories | Boots Vitamins Quiz", pathname);
          break;
        case "/result/additional-products":
          sendPageView("Additional Products | Boots Vitamins Quiz", pathname);
          break;
        case "/result/final-bundle":
          sendPageView("Final Bundle | Boots Vitamins Quiz", pathname);
          break;
        case "/result/warnings":
          sendPageView("Warnings | Boots Vitamins Quiz", pathname);
          break;
        default:
          break;
      }
    }
  }, [pathname]);

  return null;
};

export const trackEvent = (eventName, data = {}, products = []) => {
    trackGA4Event(eventName, data, products);
    trackBQEvent(eventName, data, products);
};