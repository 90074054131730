import styled, { css } from 'styled-components';
import { media } from './utilities/grid';

export const theme = {
  pinkBackground: '#fadad3',
  bootsBlue: '#05054b',
  error: '#C03',
  success: '#00a696',
  white: '#ffffff',
  greenBlue: '#d6f1ee',
  borderGreen: '#00a696',
  textGrey: '#616161',
  mustardBackground: '#fceed3',
  noteBackground: '#FEF8D2',
  lightGrey: '#f2f2f2',
  greenBackground: '#7BBB5B',
  greenBackgroundLighter: '#AACD92',
  greyBackground: '#F3F1F3',
  zigguratBackground: '#BDE1DB',
  lightZigguratBackground: '#E5F3F1',
  redBackground: '#CC0033',
  lightGreenBackground: '#E0EDD7',
  black: '#000000',
  yellowBackground: '#FCEB8B',
};

export const defaultBackground = css`
  background-color: ${theme.pinkBackground};
`;

export const sizes = {
  maxWidth: '1440px',
};

export const bodyText = styled.p`
  font-size: 18px;
  ${media.from.desktop`
    font-size: 22px;
  `}
`;

export const headerBold = styled.h3`
  font-size: 28px;
  ${media.from.desktop`
    font-size: 36px;
  `}
`;

export const bodyTitleSmall = styled.p`
  font-size: 14px;
  font-weight: bold;
  ${media.from.desktop`
    font-size: 18px;
  `}
`;

export const bodySmallText = styled.p`
  font-size: 14px;
  ${media.from.desktop`
    font-size: 18px;
  `}
`;
