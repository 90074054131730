import ReactGA from "react-ga4";

export const initGA4 = () => {
  ReactGA.initialize(process.env.REACT_APP_X_GA4_MEASUREMENT_ID);
};

export const sendGA4PageView = (title, location) => {

  ReactGA.send({
    hitType: "pageview",
    page_title: title,
    page_location: location
  });
} ;

export const trackGA4Event = (eventName, data = {}, products = []) => {

  if (products.length > 0) {
    data = {
      ...data,
      ...mapProductsToGA4EventData(products)
    };
  }

  ReactGA.event(eventName, {
    ...data
  });
};

const mapProductsToGA4EventData = (products) => {

  let totalPrice = 0;
  const mappedItems = [];
  products.forEach(product => {
    const productPrice = (product.offerPrice ? product.offerPrice : product.rrpPrice) / 100; // rrpPrice is in pennies, so we need to divide it by 100
    const discount = product.offerPrice ? product.rrpPrice - product.offerPrice : 0;
    totalPrice += productPrice;
    mappedItems.push({
      item_id: product.id,
      item_brand: product.brand,
      item_name: product.name,
      item_category: 'Supplements',
      item_category2: product.type === 'multi' ? 'Multivitamins' : 'Vitamins',
      discount: discount,
      price: productPrice,
      quantity: 1
    });
  });
  return {
    value: totalPrice,
    items: mappedItems
  }
}
