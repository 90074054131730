// Modal as a separate component
import React, {useState, useEffect } from "react";
import { StyledModal, ModalOverlay } from "./partialStyles";

function Modal({ show, modalStyle, className, children }) {

  useEffect(() => {
    if (show) {
      setIsShowing(true);
    } else {
      setIsShowing(false);
    }
  }, [show]);

  const [ isShowing, setIsShowing ] = useState(false);
  const shouldRenderChild = useDelayUnmount(isShowing, 500);
  
  let classes = className;
  if (modalStyle === "reachable") {
    classes += " reachable";
  } else if (modalStyle === "dropdown") {
    classes += " dropdown";
  }

  if (!isShowing) {
    classes += " closing";
  }

  function useDelayUnmount(isShowing, delayTime) {
    const [ shouldRender, setShouldRender ] = useState(false);

    useEffect(() => {
        let timeoutId;
        if (isShowing && !shouldRender) {
            setShouldRender(true);
        }
        else if(!isShowing && shouldRender) {
            timeoutId = setTimeout(
                () => setShouldRender(false), 
                delayTime
            );
        }
        return () => clearTimeout(timeoutId);
    }, [isShowing, delayTime, shouldRender]);
    return shouldRender;
  }

  return (
    <>
      {shouldRenderChild && 
        <StyledModal className={classes}>
          <ModalOverlay></ModalOverlay>
          <section className="modal-content">
            {children}
          </section>
        </StyledModal>
      }
    </>
  );
}

export default Modal;