import React from 'react';
import { useQuery } from 'react-apollo';
import { GET_CONFIG, setLocalData } from '../services/dataServices';

export const ApiDataContext = React.createContext(null);

ApiDataContext.displayName = 'ApiDataContext';
ApiDataContext.Consumer.displayName = 'ApiDataContext';

export const ApiDataContextProvider = props => {
  const configData = useQuery(GET_CONFIG);

  if (configData && configData.loading)
    return null;

  if (configData)
    setLocalData('configData', configData.data);


  return (
    <ApiDataContext.Provider
      value={{
        configData,
      }}
    >
      {props.children}
    </ApiDataContext.Provider>
  );
};
